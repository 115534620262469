const state = {
  // 定义全局的 isEditing 状态
  isEditing: false
};

const mutations = {
  // 定义修改 isEditing 状态的 mutation
  SET_EDITING(state, isEditing) {
    state.isEditing = isEditing;
  }
};

const actions = {
  setEditing({ commit }, data) {
    commit("SET_EDITING", data);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
