import ApiService from "@/common/api.service";

export default {
  getBooks() {
    return ApiService.get("word-list-categories");
  },
  getBooksLists(wordListCategory) {
    return ApiService.get(`word-list-categories/${wordListCategory}`);
  },
  getWords(data) {
    return ApiService.query("word-lists/words", data);
  },
  getVocabs(payload) {
    return ApiService.query("vocab/list", payload);
  },
  starWord(wordId){
    return ApiService.post("words/" + wordId + "/favorite");
  },
  getStarWords() {
    return ApiService.query("words/favorites");
  },
  getMnemonics(wordId) {
    return ApiService.query("words/" + wordId);
  },
  getAIMnemonics(wordId) {
    return ApiService.post("ai/words/" + wordId + "/word-mnemonic");
  },
  addMnemonics(wordId, data) {
    return ApiService.post("words/" + wordId + "/word-mnemonics", data);
  },
  deleteMnemonic(wordId) {
    return ApiService.delete("word-mnemonics/" +  wordId);
  },
  updateMnemonic(wordId, data) {
    return ApiService.patch("word-mnemonics/" + wordId, data);
  },
  saveLike(wordId){
    return ApiService.post("word-mnemonics/" + wordId + "/like");
  },
  recordVocab(payload) {
    return ApiService.post("vocab/usage", payload);
  },
  createMnemonic(data) {
    return ApiService.post("mnemonic", data);
  },
  // deleteMnemonic(id) {
  //   return ApiService.delete("mnemonic/" +  id);
  // },
 
  // getMnemonics(data) {
  //   return ApiService.query("mnemonics", data);
  // },

  likedByme(id) {
    return ApiService.query(`vocab-back/${id}/is-liked-by-me`);
  },
  saveWordLike(id) {
    return ApiService.post(`vocab-back/${id}/toggle-like`);
  },
  uploadImg(data) {
    return ApiService.post("mnemonics/file", data);
  }
};
